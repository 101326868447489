"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    props: {
        data: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        tableCols: {
            type: Object,
            required: true,
        },
        colorCol: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            indiceSearch: '',
        };
    },
    computed: {
        filteredData: function () {
            var _this = this;
            var _a;
            return (_a = this.data) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
                var INDEX = _a.INDEX, SYMBOL = _a.SYMBOL;
                return (INDEX || SYMBOL).toLowerCase().indexOf(_this.indiceSearch.toLowerCase()) > -1;
            });
        },
    },
    methods: {
        tableRowClassName: function (_a) {
            var row = _a.row;
            if (row[this.colorCol] > 0) {
                return 'success-row';
            }
            else if (row[this.colorCol] < 0) {
                return 'danger-row';
            }
        },
        rowClicked: function (row) {
            if (row.SYMBOL) {
                this.$router.push({
                    name: 'stock',
                    params: {
                        symbol: row.SYMBOL,
                    },
                });
            }
        },
    },
});
