"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
var StockView_vue_1 = require("@/components/StockView.vue");
var PortfolioView_vue_1 = require("@/components/PortfolioView.vue");
var home_vue_1 = require("@/components/home.vue");
var admin_vue_1 = require("@/components/admin.vue");
vue_1.default.use(vue_router_1.default);
var routes = [
    {
        path: '/',
        name: 'home',
        component: home_vue_1.default,
    },
    {
        path: '/stock/:symbol',
        name: 'stock',
        component: StockView_vue_1.default,
    },
    {
        path: '/portfolio',
        name: 'portfolio',
        component: PortfolioView_vue_1.default,
    },
    {
        path: '/admin',
        name: 'admin',
        component: admin_vue_1.default,
    },
];
var router = new vue_router_1.default({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
});
router.beforeEach(function (to, from, next) {
    if (to.name !== 'stock') {
        document.title = 'Stonks';
    }
    next();
});
exports.default = router;
