"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var moment = require("moment");
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    props: {
        stock: {
            type: Object,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        shareholders: {
            type: Object,
            required: true,
        },
    },
    computed: {
        marketCap: function () {
            return ((this.lastDayData['Close Price'] * this.shareholders.totalShares) /
                Math.pow(10, 7)).toLocaleString('en-IN', {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            });
        },
        isDataReady: function () {
            var _a;
            return ((_a = this.data) === null || _a === void 0 ? void 0 : _a.length) > 0;
        },
        lastDayData: function () {
            var data = _.last(this.data);
            return __assign(__assign({}, data), { Date: moment(data.Date).format('DD MMM'), change: {
                    value: parseFloat("" + (data['Close Price'] - data['Prev Close'])).toFixed(2),
                    percentage: Math.abs(parseFloat("" + ((data['Close Price'] - data['Prev Close']) / data['Prev Close']) * 100)).toFixed(2),
                    isUp: data['Close Price'] > data['Prev Close'],
                } });
        },
    },
});
