"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    data: function () {
        return {
            searchText: '',
        };
    },
    props: {
        portfolio: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        isCurrentHolding: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        filteredCurrentHolingsData: function () {
            var _this = this;
            var _a;
            return (_a = this.portfolio) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
                var symbol = _a.symbol;
                return symbol.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1;
            });
        },
    },
    methods: {
        sortByTotalChange: function (row) { return parseFloat(row.totalChange.replaceAll(',', '')); },
        sortByTotal: function (row) { return parseFloat(row.total.replaceAll(',', '')); },
        sortByCurrentValue: function (row) { return parseFloat(row.currentValue.replaceAll(',', '')); },
        sortBytotalInvestment: function (row) { return parseFloat(row.totalInvestment.replaceAll(',', '')); },
        sortByltp: function (row) { return parseFloat(row.ltp.replaceAll(',', '')); },
        sortByaverageBuyPrice: function (row) { return parseFloat(row.averageBuyPrice.replaceAll(',', '')); },
        sortByDate: function (row) { return moment(row.trade_date, 'DD MMM YYYY').valueOf(); },
        sortByDayPercentage: function (row) { return parseFloat(row.dayChangePercentage.replaceAll('%', '')); },
        sortByTotalPercentage: function (row) { return parseFloat(row.totalChangePercentage.replaceAll('%', '')); },
        tableRowClassName: function (_a) {
            var row = _a.row, column = _a.column;
            if (['totalChange', 'totalChangePercentage'].includes(column.property)) {
                if (row.isProfitable) {
                    return 'success-row';
                }
                return 'danger-row';
            }
            if (column.property === 'dayChangePercentage') {
                if (row.isDayProfitable) {
                    return 'success-row';
                }
                return 'danger-row';
            }
        },
    },
});
