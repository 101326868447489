"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var App_vue_1 = require("./App.vue");
var router_1 = require("./router");
var store_1 = require("./store");
require("./registerServiceWorker");
var elementui = require("element-ui");
require("element-ui/lib/theme-chalk/index.css");
vue_1.default.use(elementui);
new vue_1.default({
    router: router_1.default,
    store: store_1.default,
    render: function (h) { return h(App_vue_1.default); },
}).$mount('#app');
