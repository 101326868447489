import { render, staticRenderFns } from "./tableCard.vue?vue&type=template&id=bcea7c8e&scoped=true&"
import script from "./tableCard.vue?vue&type=script&lang=ts&"
export * from "./tableCard.vue?vue&type=script&lang=ts&"
import style0 from "./tableCard.vue?vue&type=style&index=0&id=bcea7c8e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcea7c8e",
  null
  
)

export default component.exports