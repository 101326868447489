"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("../api");
var vue_1 = require("vue");
var moment = require("moment");
var tableCard_vue_1 = require("./tableCard.vue");
exports.default = vue_1.default.extend({
    components: {
        tableCard: tableCard_vue_1.default,
    },
    data: function () {
        return {
            isLoading: true,
            dailyReport: {},
            indiceTable: {
                data: [],
                title: 'Indices',
                colorCol: 'GAIN/LOSS',
                tableCols: {
                    INDEX: {
                        label: 'Index',
                        sortable: true,
                    },
                    '%CHANGE': {
                        label: 'Change',
                        sortable: true,
                    },
                    CLOSE: {
                        label: 'Close Price',
                        sortable: true,
                    },
                },
            },
            topGainTable: {
                data: [],
                title: 'Top Gainers',
                colorCol: '%CHANGE',
                tableCols: {
                    SYMBOL: {
                        label: 'Symbol',
                        sortable: true,
                    },
                    '%CHANGE': {
                        label: 'Change',
                        sortable: true,
                    },
                    'CLOSE PRICE': {
                        label: 'Close Price',
                        sortable: true,
                    },
                },
            },
            topLossTable: {
                data: [],
                title: 'Top Losers',
                colorCol: '%CHANGE',
                tableCols: {
                    SYMBOL: {
                        label: 'Symbol',
                        sortable: true,
                    },
                    '%CHANGE': {
                        label: 'Change',
                        sortable: true,
                    },
                    'CLOSE PRICE': {
                        label: 'Close Price',
                        sortable: true,
                    },
                },
            },
            topSecuritiesTable: {
                data: [],
                title: 'Top Changes',
                colorCol: '%VAR',
                tableCols: {
                    SYMBOL: {
                        label: 'Symbol',
                        sortable: true,
                    },
                    '%VAR': {
                        label: 'Change',
                        sortable: true,
                    },
                    'CLOSE PRICE': {
                        label: 'Close Price',
                        sortable: true,
                    },
                    'VALUE(Rs Crs)': {
                        label: 'Value (crores)',
                        sortable: true,
                    },
                },
            },
        };
    },
    computed: {
        date: function () {
            return moment(this.dailyReport.date, 'DD-MMM-YYYY').format('DD MMM YYYY');
        },
        nifty50: function () {
            var _a, _b, _c;
            if (!((_a = this.dailyReport) === null || _a === void 0 ? void 0 : _a.indicesOverview)) {
                return {};
            }
            var nifty50 = (_c = (_b = this.dailyReport) === null || _b === void 0 ? void 0 : _b.indicesOverview) === null || _c === void 0 ? void 0 : _c.find(function (_a) {
                var INDEX = _a.INDEX;
                return INDEX === 'Nifty 50';
            });
            // calculate change and percentage
            var change = nifty50.CLOSE - nifty50['PREVIOUS CLOSE'];
            var percentage = (change / nifty50['PREVIOUS CLOSE']) * 100;
            return __assign(__assign({}, nifty50), { change: change.toFixed(2), percentage: Math.abs(percentage).toFixed(2) });
        },
    },
    created: function () {
        this.getData();
    },
    methods: {
        tableRowClassName: function (_a) {
            var row = _a.row;
            if (row['GAIN/LOSS'] > 0) {
                return 'success-row';
            }
            else if (row['GAIN/LOSS'] < 0) {
                return 'danger-row';
            }
        },
        getData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var apiData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api_1.default.getDailyReport(moment().valueOf().toString())];
                        case 1:
                            apiData = (_a.sent()).data;
                            this.dailyReport = apiData;
                            this.indiceTable.data = apiData.indicesOverview.map(function (indice) {
                                return __assign(__assign({}, indice), { '%CHANGE': (((indice.CLOSE - indice['PREVIOUS CLOSE']) / indice['PREVIOUS CLOSE']) * 100).toFixed(2) });
                            });
                            this.topGainTable.data = apiData.top5Gainers;
                            this.topLossTable.data = apiData.top5Losers;
                            this.topSecuritiesTable.data = apiData.top25Securities;
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
