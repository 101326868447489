"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var miniChart_vue_1 = require("./miniChart.vue");
exports.default = vue_1.default.extend({
    components: {
        miniChart: miniChart_vue_1.default,
    },
    props: ['data', 'stock'],
    computed: {
        isDataReady: function () {
            var _a;
            return ((_a = this.data) === null || _a === void 0 ? void 0 : _a.length) > 0;
        },
        lastTenData: function () {
            return this.data.slice(-10);
        },
    },
});
