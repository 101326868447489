"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var moment = require("moment");
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    data: function () {
        return {
            months: [],
            totalInvestment: 0,
            totalSell: 0,
        };
    },
    props: {
        trades: Array,
    },
    created: function () {
        var totalBuy = 0;
        var totalSell = 0;
        var months = lodash_1.groupBy(this.trades, 'month');
        var _loop_1 = function (month, trades) {
            var allTrades = trades;
            var totalInvested = 0;
            var buyTrades = [];
            allTrades.forEach(function (trade) {
                trade.total = trade.quantity * trade.price;
                trade.order_execution_time_readable = moment(trade.order_execution_time).fromNow();
                trade.order_execution_time_full = moment(trade.order_execution_time).format('DD MMM YYYY - hh:mm A');
                if (trade.trade_type === 'buy') {
                    totalInvested += trade.total;
                    totalBuy += trade.total;
                    buyTrades.push(trade);
                }
                else {
                    totalSell += trade.total;
                }
            });
            if (buyTrades.length) {
                this_1.months.push({
                    month: month,
                    trades: buyTrades,
                    totalInvested: totalInvested.toFixed(2),
                });
            }
            this_1.totalInvestment = totalBuy;
            this_1.totalSell = totalSell;
        };
        var this_1 = this;
        for (var _i = 0, _a = Object.entries(months); _i < _a.length; _i++) {
            var _b = _a[_i], month = _b[0], trades = _b[1];
            _loop_1(month, trades);
        }
    },
    methods: {
        sortByCurrentValue: function (row) {
            return moment(row.month);
        },
        sortByExecutionTime: function (row) {
            return moment(row.order_execution_time_full, 'DD MMM YYYY - hh:mm A').valueOf();
        },
        sortByTotalInvested: function (row) {
            return Number(row.totalInvested);
        },
    }
});
