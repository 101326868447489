"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var chart_js_1 = require("chart.js");
var moment = require("moment");
exports.default = vue_1.default.extend({
    props: {
        data: {
            type: Array,
            required: true,
        },
        companyData: {
            type: Object,
            required: true,
        },
        attribute: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            required: false,
            default: 1500,
        },
        height: {
            type: Number,
            required: false,
            default: 500,
        },
        options: {
            type: Object,
            required: false,
        },
    },
    data: function () {
        return {
            pixelRatio: 1,
            canvas: null,
            ctx: null,
            mappedCompanyData: {},
            plugin: {
                id: 'custom_canvas_background_color',
                beforeDraw: function (chart) {
                    var ctx = chart.canvas.getContext('2d');
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, chart.width, chart.height);
                    ctx.restore();
                },
            },
        };
    },
    mounted: function () {
        var _this = this;
        chart_js_1.Chart.register.apply(chart_js_1.Chart, chart_js_1.registerables);
        // Chart.register(CrosshairPlugin);
        // Interaction.modes.interpolate = Interpolate
        var canvas = document.getElementById('mainChart');
        var ctx = canvas.getContext('2d');
        var labels = this.data.map(function (item) { return moment(item.Date).format('DD MMM YYYY'); });
        this.getMappedCompanyData();
        var chart = new chart_js_1.Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: this.attribute,
                        data: this.data.map(function (item) { return item[_this.attribute]; }),
                        fill: false,
                        borderColor: 'black',
                        pointRadius: 0,
                        borderWidth: 1.5,
                    },
                    {
                        label: '50 Day MA',
                        data: this.data.map(function (item) { return item['50 Day MA']; }),
                        fill: false,
                        borderColor: 'green',
                        pointRadius: 0,
                        borderWidth: 1.5,
                        hidden: !this.options.show50ma,
                    },
                    {
                        label: '200 Day MA',
                        data: this.data.map(function (item) { return item['200 Day MA']; }),
                        fill: false,
                        borderColor: 'orange',
                        pointRadius: 0,
                        borderWidth: 1.5,
                        hidden: !this.options.show200ma,
                    },
                    {
                        label: 'Dividend',
                        data: this.mappedCompanyData.dividends.map(function (item) { return item.y; }),
                        fill: false,
                        borderColor: 'green',
                        pointBackgroundColor: 'green',
                        pointRadius: 7,
                    },
                    {
                        label: 'Split',
                        data: this.mappedCompanyData.splits.map(function (item) { return item.y; }),
                        fill: false,
                        borderColor: 'blue',
                        pointBackgroundColor: 'blue',
                        pointRadius: 7,
                    },
                    {
                        label: 'Bonus',
                        data: this.mappedCompanyData.bonuses.map(function (item) { return item.y; }),
                        fill: false,
                        borderColor: 'orange',
                        pointBackgroundColor: 'orange',
                        pointRadius: 7,
                    },
                    {
                        yAxisID: 'volumeYAxis',
                        label: 'Volume',
                        data: this.data.map(function (item) { return item['Total Traded Quantity']; }),
                        type: 'bar',
                        backgroundColor: 'rgba(63, 103, 255, 0.3)',
                        borderColor: 'rgba(63, 103, 255, 0.3)',
                        hoverBorderColor: 'red',
                        hoverBackgroundColor: 'red',
                        borderWidth: 1.5,
                        hidden: !this.options.showVolume,
                    },
                    {
                        yAxisID: 'deliveryYAxis',
                        label: 'Delivery %',
                        data: this.data.map(function (item) { return item['% Dly Qt to Traded Qty']; }),
                        type: 'line',
                        fill: false,
                        borderColor: 'teal',
                        pointRadius: 0,
                        borderWidth: 1,
                        hidden: !this.options.showDeliveryPercentage,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            lineWidth: 0.5,
                            drawBorder: false,
                            display: false,
                        },
                        ticks: {
                            maxRotation: 0,
                            color: 'black',
                            maxTicksLimit: 10,
                        },
                    },
                    y: {
                        position: 'right',
                        grid: {
                            drawBorder: false,
                            lineWidth: 0.3,
                            color: 'darkgrey',
                        },
                        ticks: {
                            maxRotation: 0,
                            color: 'black',
                        },
                    },
                    volumeYAxis: {
                        display: this.options.showVolume,
                        position: 'left',
                        grid: {
                            display: false,
                        },
                        ticks: {
                            maxRotation: 0,
                            color: 'black',
                        },
                    },
                    deliveryYAxis: {
                        display: this.options.showDeliveryPercentage && !this.options.showVolume,
                        position: 'left',
                        ticks: {
                            maxRotation: 0,
                            color: 'black',
                        },
                        grid: {
                            display: false,
                        },
                        suggestedMax: 100,
                        suggestedMin: 0,
                    },
                },
                plugins: {
                    legend: {
                        position: 'top',
                        display: false,
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        bodyColor: 'black',
                        titleColor: 'black',
                        titleFont: {
                            size: 15,
                        },
                        backgroundColor: '#f5f5f5',
                        borderColor: '#aaa',
                        borderWidth: 1,
                        bodyFont: {
                            size: 15,
                        },
                        boxPadding: 10,
                        padding: 10,
                        position: 'nearest',
                        callbacks: {
                            label: function (context) {
                                var label = (context.dataset.label || '') + " " + context.formattedValue;
                                if (context.dataset.label === 'Dividend') {
                                    label = (context.dataset.label || '') + " Rs." + _this.mappedCompanyData.dividends[context.dataIndex].value;
                                }
                                if (context.dataset.label === 'Split') {
                                    label = (context.dataset.label || '') + " from Rs." + _this.mappedCompanyData.splits[context.dataIndex].value.old + " to Rs." + _this.mappedCompanyData.splits[context.dataIndex].value.new;
                                }
                                if (context.dataset.label === 'Bonus') {
                                    label = (context.dataset.label || '') + " " + _this.mappedCompanyData.bonuses[context.dataIndex].value;
                                }
                                return label;
                            },
                        },
                    },
                },
            },
            plugins: [this.plugin],
        });
    },
    methods: {
        getMappedCompanyData: function () {
            var _this = this;
            var _loop_1 = function (action) {
                var actions = this_1.companyData[action].map(function (item) { return (__assign(__assign({}, item), { date: moment(item.date, 'DD-MMM-YYYY').valueOf() })); });
                var actionDates = actions.map(function (_a) {
                    var date = _a.date;
                    return date;
                });
                var mappedActions = this_1.data.map(function (item) {
                    if (actionDates.includes(item.Date)) {
                        return {
                            y: item[_this.attribute],
                            value: actions.find(function (_a) {
                                var date = _a.date;
                                return date === item.Date;
                            }).value,
                        };
                    }
                    return {
                        y: null,
                    };
                });
                this_1.mappedCompanyData[action] = mappedActions;
            };
            var this_1 = this;
            for (var action in this.companyData) {
                _loop_1(action);
            }
        },
    },
});
