"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    props: {
        allSymbols: {
            type: Array,
            required: true,
        },
        updateSymbol: {
            type: Function,
            required: true,
        },
        marketOverview: Object,
    },
    data: function () {
        return {
            activeIndex: 'home',
            symbol: '',
        };
    },
    methods: {
        handleSelect: function (index) {
            this.activeIndex = index;
        },
        goToStock: function (symbol) {
            this.$router.push({
                name: 'stock',
                params: {
                    symbol: symbol,
                },
            });
        },
        getMarketDate: function (date) {
            return moment(date).format('DD MMM, HH:mm');
        }
    },
});
