"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
var axios_1 = require("axios");
var server = axios_1.default.create({
    baseURL: ((_a = process.env.VUE_APP_IS_PROD) === null || _a === void 0 ? void 0 : _a.length) ?
        window.location.protocol + "//" + window.location.hostname + "/node/" :
        window.location.protocol + "//" + window.location.hostname + ":5500/",
});
var api = {
    getDailyReport: function (day) { return server.get('getDailyReport', { params: { day: day } }); },
    getHistory: function (symbol) { return server.get('history', { params: { symbol: symbol } }); },
    getShareholders: function (symbol) { return server.get('getShareholders', { params: { symbol: symbol } }); },
    getAllHistory: function (symbol) { return server.get('allHistory', { params: { symbol: symbol } }); },
    getCompanyData: function (symbol) { return server.get('companyData', { params: { symbol: symbol } }); },
    getIntegrity: function () { return server.get('getIntegrity'); },
    getAllSymbols: function () { return server.get('getAllEquities'); },
    getMarketOverview: function () { return server.get('marketOverview'); },
    getPortfolio: function () { return server.get('getPortfolio'); },
};
exports.default = api;
