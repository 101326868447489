"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("../api");
var _ = require("lodash");
var moment = require("moment");
var stockOverview_vue_1 = require("./stockOverview.vue");
var extraCharts_vue_1 = require("./extraCharts.vue");
var mainChart_vue_1 = require("./mainChart.vue");
var topBar_vue_1 = require("./topBar.vue");
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    components: {
        stockOverview: stockOverview_vue_1.default,
        extraCharts: extraCharts_vue_1.default,
        topBar: topBar_vue_1.default,
        mainChart: mainChart_vue_1.default,
    },
    props: ['allSymbols', 'symbol'],
    created: function () {
        this.getData();
        document.title = this.stock.SYMBOL;
    },
    watch: {
        '$route.params.symbol': function () {
            this.getData();
        },
    },
    data: function () {
        return {
            canvas: null,
            ctx: null,
            showVolume: false,
            show50ma: false,
            show200ma: false,
            showDeliveryPercentage: false,
            isLoading: false,
            pixelRatio: Math.ceil(window.devicePixelRatio || 1),
            stockDetails: {
                data: [],
                prices: [],
                min: 0,
                max: 0,
            },
            companyData: {},
            shareholders: {},
            apiData: [],
            timeSpan: '1Y',
            plotSettings: {
                veritcalPadding: 50,
                horizontalPadding: 60,
                width: Math.ceil(screen.width * 0.9),
                height: Math.ceil(screen.height * 0.35),
            },
            volumePlotSettings: {
                veritcalPadding: 50,
                horizontalPadding: 60,
                width: Math.ceil(screen.width * 0.9),
                height: Math.ceil(screen.height * 0.3),
            },
        };
    },
    computed: {
        stock: function () {
            var _this = this;
            return this.allSymbols.find(function (item) { return item.value === _this.$route.params.symbol; });
        },
        percentageFromMax: function () {
            return (((this.stockDetails.max - this.stockDetails.prices[this.stockDetails.prices.length - 1]) /
                this.stockDetails.max) *
                100).toFixed(1);
        },
        cagr: function () {
            if (this.stockDetails.prices.length) {
                var lastPrice = this.stockDetails.prices[this.stockDetails.prices.length - 1];
                var firstPrice = this.stockDetails.prices[0];
                var days = moment(this.stockDetails.data[this.stockDetails.data.length - 1].Date).diff(moment(this.stockDetails.data[0].Date), 'days');
                var years = Math.ceil(days / 365);
                if (this.timeSpan === 'Max') {
                    years = 1;
                }
                var cagr = (Math.pow(lastPrice / firstPrice, 1 / years) - 1) * 100;
                return cagr.toFixed(1);
            }
        },
        chartKey: function () {
            var _a;
            return ((this.stockDetails.data[0] ? (_a = Object.values(this.stockDetails.data[0])) === null || _a === void 0 ? void 0 : _a.toString() : '-') +
                Object.values(this.chartOptions).toString());
        },
        chartOptions: function () {
            return {
                showVolume: this.showVolume,
                show50ma: this.show50ma,
                show200ma: this.show200ma,
                showDeliveryPercentage: this.showDeliveryPercentage,
            };
        },
    },
    methods: {
        getData: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.getHistory();
                    this.getShareholderData();
                    return [2 /*return*/];
                });
            });
        },
        getShareholderData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api_1.default.getShareholders(this.$route.params.symbol)];
                        case 1:
                            data = (_a.sent()).data;
                            this.shareholders = data;
                            return [2 /*return*/];
                    }
                });
            });
        },
        getHistory: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, data, companyData, lastDayData;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.isLoading = true;
                            return [4 /*yield*/, Promise.all([
                                    api_1.default.getAllHistory(this.$route.params.symbol),
                                    api_1.default.getCompanyData(this.$route.params.symbol),
                                ])];
                        case 1:
                            _a = _b.sent(), data = _a[0].data, companyData = _a[1].data;
                            this.companyData = companyData;
                            this.apiData = data.map(function (data) { return (__assign(__assign({}, data), { Date: moment(data.Date, 'DD-MMM-YYYY').valueOf() })); });
                            this.apiData.forEach(function (data, index) {
                                // calculate 50 day MA
                                if (index >= 49) {
                                    var sum = _this.apiData
                                        .slice(index - 49, index + 1)
                                        .reduce(function (acc, curr) { return acc + curr['Close Price']; }, 0);
                                    _this.apiData[index]['50 Day MA'] = (sum / 50).toFixed(2);
                                }
                                // calculate 200 day MA
                                if (index >= 199) {
                                    var sum = _this.apiData
                                        .slice(index - 199, index + 1)
                                        .reduce(function (acc, curr) { return acc + curr['Close Price']; }, 0);
                                    _this.apiData[index]['200 Day MA'] = (sum / 200).toFixed(2);
                                }
                            });
                            lastDayData = _.last(this.apiData);
                            lastDayData = {
                                closePrice: lastDayData['Close Price'],
                                value: parseFloat("" + (lastDayData['Close Price'] - lastDayData['Prev Close'])).toFixed(2),
                                percentage: Math.abs(parseFloat("" + ((lastDayData['Close Price'] - lastDayData['Prev Close']) / lastDayData['Prev Close']) *
                                    100)).toFixed(2),
                                isUp: lastDayData['Close Price'] > lastDayData['Prev Close'],
                            };
                            document.title = this.stock.SYMBOL + " " + lastDayData.closePrice + " " + (lastDayData.isUp ? '🟢' : '🔴') + " " + lastDayData.percentage + "%";
                            this.timeSpanChanged();
                            return [2 /*return*/];
                    }
                });
            });
        },
        timeSpanChanged: function () {
            this.isLoading = true;
            switch (this.timeSpan) {
                case '1M':
                    this.stockDetails.data = this.getLastXDays(30);
                    break;
                case '3M':
                    this.stockDetails.data = this.getLastXDays(90);
                    break;
                case '6M':
                    this.stockDetails.data = this.getLastXDays(180);
                    break;
                case '1Y':
                    this.stockDetails.data = this.getLastXDays(365);
                    break;
                case '2Y':
                    this.stockDetails.data = this.getLastXDays(730);
                    break;
                case '3Y':
                    this.stockDetails.data = this.getLastXDays(1095);
                    break;
                case '5Y':
                    this.stockDetails.data = this.getLastXDays(1825);
                    break;
                case 'Max':
                    this.stockDetails.data = this.apiData;
                    break;
            }
            this.stockDetails.prices = this.stockDetails.data.map(function (day) { return day['Close Price']; });
            if (this.show50ma) {
                this.stockDetails.fdma = this.stockDetails.data.map(function (day) { return day['50 Day MA']; });
            }
            else {
                this.stockDetails.fdma = [];
            }
            if (this.show200ma) {
                this.stockDetails.tdma = this.stockDetails.data.map(function (day) { return day['200 Day MA']; });
            }
            else {
                this.stockDetails.tdma = [];
            }
            this.stockDetails.volumes = this.stockDetails.data.map(function (day) { return day['Total Traded Quantity']; });
            this.stockDetails.volumesDelivery = this.stockDetails.data.map(function (day) { return day['Deliverable Qty']; });
            this.stockDetails.priceMin = Math.min.apply(Math, this.stockDetails.prices);
            this.stockDetails.priceMax = Math.max.apply(Math, this.stockDetails.prices);
            this.stockDetails.min = Math.min.apply(Math, __spreadArrays(this.stockDetails.prices, this.stockDetails.fdma.filter(Boolean), this.stockDetails.tdma.filter(Boolean)));
            this.stockDetails.max = Math.max.apply(Math, this.stockDetails.prices);
            this.stockDetails.minVol = Math.min.apply(Math, this.stockDetails.volumes);
            this.stockDetails.maxVol = Math.max.apply(Math, this.stockDetails.volumes);
            this.isLoading = false;
            // this.drawPriceChart();
            // if (this.showVolume) {
            //   this.drawVolumeChart();
            // }
        },
        getLastXDays: function (days) {
            return this.apiData.filter(function (day) {
                return moment(day.Date).isSameOrAfter(moment().subtract(days + 1, 'days'));
            });
        },
        createChart: function () {
            var canvas = document.createElement('canvas');
            canvas.id = 'priceChart';
            canvas.width = this.plotSettings.width * this.pixelRatio;
            canvas.height = this.plotSettings.height * this.pixelRatio;
            canvas.style.width = this.plotSettings.width + 'px';
            canvas.style.height = this.plotSettings.height + 'px';
            this.canvas = canvas;
            this.ctx = canvas.getContext('2d');
            this.ctx.transform(this.pixelRatio, 0, 0, this.pixelRatio, 0, 0);
            document.getElementById('canvasContainer').innerHTML = '';
            document.getElementById('canvasContainer').appendChild(canvas);
        },
        createVolumeChart: function () {
            var canvas = document.createElement('canvas');
            canvas.id = 'volumeChart';
            canvas.width = this.volumePlotSettings.width * this.pixelRatio;
            canvas.height = this.volumePlotSettings.height * this.pixelRatio;
            canvas.style.width = this.volumePlotSettings.width + 'px';
            canvas.style.height = this.volumePlotSettings.height + 'px';
            this.canvas = canvas;
            this.ctx = canvas.getContext('2d');
            this.ctx.transform(this.pixelRatio, 0, 0, this.pixelRatio, 0, 0);
            document.getElementById('canvasContainer').appendChild(canvas);
        },
        drawVolumeChart: function () {
            this.plotVolumeChart();
        },
        drawPriceChart: function () {
            this.createChart();
            this.ctx.translate(0, this.plotSettings.height);
            this.ctx.scale(1, -1);
            if (this.show50ma) {
                this.plotLineChart(this.stockDetails.fdma, 'orange');
                this.plotReference('orange', this.stockDetails.fdma[this.stockDetails.fdma.length - 1]);
            }
            if (this.show200ma) {
                this.plotLineChart(this.stockDetails.tdma, 'red');
                this.plotReference('red', this.stockDetails.tdma[this.stockDetails.tdma.length - 1]);
            }
            this.plotLineChart(this.stockDetails.prices);
            this.plotReference('green', this.stockDetails.priceMax);
            this.plotReference('red', this.stockDetails.priceMin);
            this.plotReference('black', this.stockDetails.prices[this.stockDetails.prices.length - 1]);
            this.plotDates(this.stockDetails.data[0], 0);
            this.plotDates(this.stockDetails.data[this.stockDetails.data.length - 1], this.stockDetails.data.length - 1);
            this.plotDates(this.stockDetails.data[Math.ceil(this.stockDetails.data.length * 0.5)], Math.ceil(this.stockDetails.data.length * 0.5));
            this.plotDates(this.stockDetails.data[Math.ceil(this.stockDetails.data.length * 0.25)], Math.ceil(this.stockDetails.data.length * 0.25));
            this.plotDates(this.stockDetails.data[Math.ceil(this.stockDetails.data.length * 0.75)], Math.ceil(this.stockDetails.data.length * 0.75));
            this.isLoading = false;
        },
        plotLineChart: function (values, color) {
            var _this = this;
            this.ctx.beginPath();
            if (color) {
                this.ctx.strokeStyle = color;
            }
            else {
                this.ctx.strokeStyle = 'black';
            }
            var timeTrack = this.plotSettings.horizontalPadding;
            this.ctx.lineWidth = 1.5;
            values.forEach(function (price) {
                if (price) {
                    var closePrice = _this.getPlotY(price);
                    _this.ctx.lineTo(timeTrack, closePrice);
                }
                timeTrack +=
                    (_this.plotSettings.width - 2 * _this.plotSettings.horizontalPadding) /
                        _this.stockDetails.data.length;
            });
            this.ctx.stroke();
        },
        plotVolumeChart: function () {
            var _this = this;
            this.ctx.beginPath();
            this.ctx.fillStyle = 'rgba(30, 30, 30, 0.3)';
            var timeTrack = this.volumePlotSettings.horizontalPadding;
            this.ctx.lineWidth = 0.5;
            this.ctx.moveTo(this.getPlotXVolume(0), this.getPlotYVolume(0));
            this.stockDetails.volumes.forEach(function (volume) {
                var closePrice = _this.getPlotYVolume(volume);
                _this.ctx.lineTo(timeTrack, closePrice);
                timeTrack +=
                    (_this.volumePlotSettings.width - 2 * _this.volumePlotSettings.horizontalPadding) /
                        _this.stockDetails.data.length;
            });
            this.ctx.lineTo(timeTrack, this.getPlotYVolume(0));
            this.ctx.lineTo(this.plotSettings.width - this.plotSettings.horizontalPadding, this.getPlotYVolume(0));
            this.ctx.lineTo(this.getPlotXVolume(0), this.getPlotYVolume(0));
            this.ctx.closePath();
            this.ctx.fill();
            this.ctx.beginPath();
            this.ctx.moveTo(this.getPlotXVolume(0), this.getPlotYVolume(0));
            this.ctx.fillStyle = 'rgba(0, 0, 0, 0.52)';
            timeTrack = this.volumePlotSettings.horizontalPadding;
            this.stockDetails.volumesDelivery.forEach(function (volume) {
                var closePrice = _this.getPlotYVolume(volume);
                _this.ctx.lineTo(timeTrack, closePrice);
                timeTrack +=
                    (_this.volumePlotSettings.width - 2 * _this.volumePlotSettings.horizontalPadding) /
                        _this.stockDetails.data.length;
            });
            this.ctx.lineTo(timeTrack, this.getPlotYVolume(0));
            this.ctx.lineTo(this.plotSettings.width - this.plotSettings.horizontalPadding, this.getPlotYVolume(0));
            this.ctx.lineTo(this.getPlotXVolume(0), this.getPlotYVolume(0));
            this.ctx.fill();
        },
        getPlotXVolume: function (index) {
            return (index *
                ((this.volumePlotSettings.width - 2 * this.volumePlotSettings.horizontalPadding) /
                    this.stockDetails.data.length) +
                this.volumePlotSettings.horizontalPadding);
        },
        getPlotYVolume: function (price) {
            return (price *
                ((this.volumePlotSettings.height - 2 * this.volumePlotSettings.veritcalPadding) /
                    this.stockDetails.maxVol) +
                this.volumePlotSettings.veritcalPadding);
        },
        getPlotY: function (price) {
            return ((price - this.stockDetails.min) *
                ((this.plotSettings.height - 2 * this.plotSettings.veritcalPadding) /
                    (this.stockDetails.max - this.stockDetails.min)) +
                this.plotSettings.veritcalPadding);
        },
        getPlotX: function (index) {
            return (index *
                ((this.plotSettings.width - 2 * this.plotSettings.horizontalPadding) /
                    this.stockDetails.data.length) +
                this.plotSettings.horizontalPadding);
        },
        getReadableDate: function (date) {
            return moment(date).format('DD MMM YYYY');
        },
        plotDates: function (dataPoint, index) {
            this.ctx.save();
            this.ctx.scale(1, -1);
            this.ctx.font = '12px Arial';
            this.ctx.fillStyle = 'black';
            var textMetrics = this.ctx.measureText(dataPoint.Date);
            this.ctx.fillText(this.getReadableDate(dataPoint.Date), this.getPlotX(index) - textMetrics.width / 2, -5);
            this.ctx.restore();
            this.ctx.save();
            this.ctx.beginPath();
            this.ctx.strokeStyle = 'grey';
            this.ctx.lineWidth = 0.5;
            this.ctx.setLineDash([10, 10]);
            this.ctx.moveTo(this.getPlotX(index), this.plotSettings.veritcalPadding);
            this.ctx.lineTo(this.getPlotX(index), this.plotSettings.height - this.plotSettings.veritcalPadding);
            this.ctx.stroke();
            this.ctx.restore();
        },
        plotReference: function (color, price, position) {
            price = parseFloat(price).toFixed(2);
            // this.ctx.lineWidth = .5;
            // this.ctx.beginPath();
            // this.ctx.strokeStyle = color;
            // this.ctx.moveTo(this.getPlotX(0), this.getPlotY(price));
            // this.ctx.lineTo(this.plotSettings.width - this.plotSettings.horizontalPadding, this.getPlotY(price));
            // this.ctx.stroke();
            // plot price text
            var horizontalPadding = 10;
            var verticalPadding = 5;
            this.ctx.save();
            this.ctx.scale(1, -1);
            this.ctx.font = '12px Arial';
            var textMetrics = this.ctx.measureText(price);
            var textY = -this.getPlotY(price);
            var boxY = -this.getPlotY(price);
            switch (position) {
                case 'bottom':
                    textY += textMetrics.actualBoundingBoxAscent;
                    boxY -= verticalPadding;
                    break;
                case 'top':
                    boxY -= textMetrics.actualBoundingBoxAscent + verticalPadding;
                    break;
                default:
                    textY += textMetrics.actualBoundingBoxAscent / 2;
                    boxY -= textMetrics.actualBoundingBoxAscent / 2 + verticalPadding;
                    break;
            }
            this.ctx.fillStyle = color;
            this.ctx.fillRect(this.plotSettings.width - this.plotSettings.horizontalPadding, boxY, textMetrics.width + horizontalPadding * 2, textMetrics.fontBoundingBoxAscent + verticalPadding * 2);
            this.ctx.fillStyle = 'white';
            this.ctx.fillText(price, this.plotSettings.width - this.plotSettings.horizontalPadding + horizontalPadding, textY);
            this.ctx.restore();
        },
    },
});
