"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var chart_js_1 = require("chart.js");
var moment = require("moment");
exports.default = vue_1.default.extend({
    props: ['data', 'attribute', 'width', 'height'],
    data: function () {
        return {
            pixelRatio: 1,
            canvas: null,
            ctx: null,
            stockDetails: {
                values: [],
                min: 0,
                max: 0,
            },
            plugin: {
                id: 'custom_canvas_background_color',
                beforeDraw: function (chart) {
                    var ctx = chart.canvas.getContext('2d');
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, chart.width, chart.height);
                    ctx.restore();
                },
            },
        };
    },
    mounted: function () {
        var _this = this;
        chart_js_1.Chart.register.apply(chart_js_1.Chart, chart_js_1.registerables);
        var canvas = document.getElementById('myChart');
        var ctx = canvas.getContext('2d');
        var labels = this.data.map(function (item) { return moment(item.Date).format('DD MMM'); });
        new chart_js_1.Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        data: this.data.map(function (item) { return parseInt(item[_this.attribute].trim()); }),
                        fill: true,
                        borderColor: 'black',
                        pointRadius: 0,
                        borderWidth: 1.5,
                    },
                ],
            },
            options: {
                responsive: false,
                scales: {
                    x: {
                        grid: {
                            lineWidth: 0.5,
                            drawBorder: false,
                        },
                        ticks: {
                            maxRotation: 0,
                            color: 'black',
                        },
                    },
                    y: {
                        grid: {
                            drawBorder: false,
                            lineWidth: 0.5,
                        },
                        ticks: {
                            callback: function (val, index) {
                                return index % 2 === 0 ? val : '';
                            },
                            maxRotation: 0,
                            color: 'black',
                        },
                        beginAtZero: true,
                        suggestedMax: 100,
                    },
                },
                plugins: {
                    legend: {
                        position: 'top',
                        display: false,
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                    },
                    title: {
                        display: true,
                        text: 'Delivery % past 10 days',
                    },
                },
            },
            plugins: [this.plugin],
        });
    },
    methods: {},
});
